@font-face {
  font-family: 'Sofia Pro';
  src: local('Sofia Pro'), url('../fonts/SofiaPro/SofiaProRegular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: local('Sofia Pro'), url('../fonts/SofiaPro/SofiaProBold.otf') format('open');
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: local('Sofia Pro'), url('../fonts/SofiaPro/SofiaProLight.otf') format('open');
  font-weight: 300;
  font-style: normal;
}
