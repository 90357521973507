.filter-datepicker .react-datepicker {
  @apply text-sm h-full font-sans rounded text-black border-0;
  width: 100%;
  background-color: #FAFAFA;
  border-radius: 8px;
}

.filter-datepicker .react-datepicker__header {
  padding-bottom: 0;
}
.filter-datepicker .react-datepicker__month {
  margin: 6px 6px;
}
.filter-datepicker .react-datepicker__current-month {
  text-align: center;
  padding-left: 0;
  font-size: 16px;
}
.filter-datepicker .react-datepicker__day-names {
  padding: 0;
}

.filter-datepicker .react-datepicker__day-name,
.filter-datepicker .react-datepicker__day {
  font-size: 14px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  margin: 0 !important;
}

.filter-datepicker .react-datepicker__day--keyboard-selected {
  background-color: #219653;
}

.filter-datepicker .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  @apply bg-green;
  color: #fff;
}

.filter-datepicker .react-datepicker__navigation--next {
  top: 10px;
  right: 6px;
}
.filter-datepicker .react-datepicker__navigation--previous {
  top: 10px;
  left: 6px;
}

