.tree_mobile {
  height: 100%;
  padding: 0 16px;
}

.tree_mobile .rc-tree-checkbox {
  margin-right: 12px !important;
}

.tree_mobile .rc-tree-treenode{
  border-bottom: 1px solid #E8E8E8;
  padding: 16px 0 !important;
}


.tree-with_count .rc-tree-switcher-noop {
  display: none !important;
}

.tree-with_count .rc-tree-node-content-wrapper {
  width: 100%;
}

.tree-with_count .rc-tree-node-content-wrapper>.rc-tree-title {
  width: 100%;
}