.rc-tree-treenode {
  @apply flex items-start pb-1 whitespace-normal mb-0 !important;
}
.rc-tree-node-content-wrapper {
  @apply h-auto min-h-[24px] p-0 cursor-pointer !important;
}
.rc-tree-checkbox {
  @apply flex-none h-4 w-4 mt-1 mr-2 ml-0 border-gray-250 border rounded-sm transition-all bg-none border-solid focus:ring-2 focus:ring-offset-2 focus:ring-green-bright !important;
}

.rc-tree-checkbox-checked {
  @apply border-transparent bg-contain bg-center bg-no-repeat bg-green-bright !important;

  background-image: url(../../images/checked.svg) !important;
}

.rc-tree-switcher {
  @apply h-6 w-6 m-0 bg-none relative flex-none !important;
}

.rc-tree-switcher_close:after {
  @apply block bg-none origin-top transition-all border-transparent border-t-black absolute w-0 h-0 border-4 top-[12px] left-[8px] rotate-[-90deg] !important;
  content: '';
}

.rc-tree-switcher_open:after {
  @apply rotate-0 !important;
}
