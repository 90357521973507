@import 'typography.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'components/buttonPrimary.css';
@import 'components/checkbox.css';
@import 'components/multiChart.css';
@import 'components/header.css';
@import 'buttonGroup.css';

:root {
  --color-orange: rgba(240, 133, 50, 1);
  --color-orange-light: rgba(240, 133, 50, 0.05);
  --color-desert: rgba(206, 124, 50, 1);
  --color-desert-light: rgba(206, 124, 50, 0.05);
  --color-pink: rgba(237, 91, 115, 1);
  --color-pink-light: rgba(237, 91, 115, 0.05);
  --color-mojo: rgba(167, 48, 41, 1);
  --color-mojo-light: rgba(167, 48, 41, 0.05);
  --color-blue: rgba(77, 115, 251, 1);
  --color-blue-light: rgba(77, 115, 251, 0.05);
  --color-violet: rgba(93, 41, 249, 1);
  --color-violet-light: rgba(93, 41, 249, 0.05);
  --color-brown: rgba(113, 1, 23, 1);
  --color-brown-light: rgba(113, 1, 23, 0.05);
  --color-cement: rgba(139, 114, 102, 1);
  --color-cement-light: rgba(139, 114, 102, 0.05);
  --color-gray-dark: rgba(133, 133, 133, 1);
  --color-gray-dark-light: rgba(133, 133, 133, 0.05);
  --color-malibu: rgba(102, 189, 241, 1);
  --color-malibu-light: rgba(102, 189, 241, 0.05);
  --color-moon: rgba(157, 176, 243, 1);
  --color-moon-light: rgba(157, 176, 243, 0.05);
  --color-bouquet: rgba(172, 117, 143, 1);
  --color-bouquet-light: rgba(172, 117, 143, 0.05);
  --color-salmon: rgba(255, 148, 169, 1);
  --color-salmon-light: rgba(255, 148, 169, 0.05);
  --color-pine: rgba(187, 196, 136, 1);
  --color-pine-light: rgba(187, 196, 136, 0.05);
  --color-mint: rgba(181, 240, 107, 1);
  --color-mint-light: rgba(143, 237, 164, 0.05);
  --color-rose: rgba(255, 77, 113, 1);
  --color-rose-light: rgba(255, 0, 138, 0.05);
  --color-salad: rgba(73, 147, 90, 1);
  --color-salad-light: rgba(73, 147, 90, 0.05);
  --color-purple: rgba(195, 100, 247, 1);
  --color-purple-light: rgba(195, 100, 247, 0.05);
  --color-green: rgba(33, 150, 83, 1);
  --color-green-light: rgba(33, 150, 83, 0.1);
  --color-gray: rgba(0, 0, 0, 0.12);
  --color-gray-light: rgba(220, 220, 220, 0.05);
  --color-black: rgba(17, 17, 17, 1);
  --color-black-light: rgba(17, 17, 17, 0.05);
  --color-grey: rgba(0, 0, 0, 0.7);
  --color-yuma: rgba(205, 175, 143, 1);

  --color-nepal: #7da0ba;
  --color-viking: #66dada;
  --color-heliotrope: #894dfb;
  --color-yellow: rgba(255, 193, 33, 1);

  --color-yellow-light: rgba(255, 193, 33, 0.25);
  --color-leaf: #8bba8a;
  --color-contessa: #c87966;
  --color-hot-pink: #ff66af;

  --color-green-hover: #f1fcf6;
  --color-gray-100: rgba(245, 245, 245, 1);
  --color-gray-500: #6E6E6E;

  --color-wb: #BE20A5;
  --color-flirt: #A00287;
  --color-ozon: #0069FF;
  --color-science-blue: #004BE1;

  --color-fuel-yellow: #ECB11B;
  --color-brandy-punch: #ECB11B;
  --color-silver-chalice: #8C8C8C;
  --color-abbey: #4F535A;
  --color-paradiso: #2D7B80;
  --color-hippie-blue: #6597AE;
  --color-barley-corn: #9B895F;
  --color-copper-rust: #A34D4D;

  /*для выравнивания уголка у HelpModal для ListModeActivateSelector*/
  --list-item-left: 0;
  /*для задания высоты стики хедера*/
  --panel-sticky-height: 0;
}

*,
::before,
::after {
  --tw-ring-color: var(--color-green);
}

@layer base {
  body {
    @apply text-sm min-h-screen font-sans;
  }
  html {
    @apply text-black h-full;
  }
  h1 {
    @apply font-bold text-2xl;
  }
  h3 {
    @apply text-base mb-3;
  }
  #root {
    @apply min-h-screen;
  }
}

a:hover,
a:active {
  color: #219653;
}
::selection {
  color: #fff;
  fill: #fff;
  background: #219653;
}

.wrapper {
  @apply px-3.5 sm:px-8 lg:px-16;
}

.wrapper-wide {
  @apply px-4;
}

.custom-tooltip {
  @apply absolute;
}

.custom-tooltip-header {
  margin-left: -35px;
  width: 280px;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}
.custom-tooltip--small {
  padding-right: 10px;
}

.triangle {
  @apply h-0 w-0 border-transparent border-black border-4;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.table-nav-label {
  @apply font-semibold block p-1 rounded text-black text-center;
  font-size: 10px;
}

.white-gradient {
  background: linear-gradient(to right, transparent, white);
}

.bg-gray-gradient {
  background: linear-gradient(to right, rgba(0, 0, 0, 0), var(--color-gray-100) 26%);
}

.bg-white-gradient {
  background: linear-gradient(to right, rgba(0, 0, 0, 0), white 26%);
}
/*Fix for duplicate tariffs modal*/
#headlessui-portal-root>div:nth-child(n+2) {
  display: none;
}
/*Styles for mobile datepicker*/
.mobile .datepicker {
  position: static !important;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
}

.mobile .datepicker li{
  font-size: 28px;
}

.mobile .datepicker .datepicker-col-1:nth-child(2n) {
  text-align: left;
}

.mobile .datepicker-content {
  padding-top: 8px !important;
}
/* чтобы задать позицию уголка для HelpModal для ListItem*/
.list-item-before-position:before {
  left: var(--list-item-left);
}
/*Скрытие ярлыка чата*/
#jvlabelWrap {
  visibility: hidden;
  opacity: 0;
}
#jvlabelWrap>jdiv {
  cursor: default !important;
}

.table_first_column_padding {
  padding-left: 32px !important;
}

.searchDateHeaderCell>div:last-child span {
  width: 100%;
  text-align: right;
}

#calendarInfo {
  text-align: center;
  padding-bottom: 16px;
  border-top: 1px solid lightgrey;
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 16px;
}