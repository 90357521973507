.react-datepicker {
  @apply text-sm h-full font-sans rounded text-black border-0;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  @apply text-sm text-black;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__header {
  @apply border-0;
  background: transparent;
  padding-top: 16px;
}
.react-datepicker__month {
  margin: 6px 20px 20px;
}
h2.react-datepicker__current-month {
  text-align: left;
  padding-left: 24px;
  padding-bottom: 12px;
  font-size: 16px;
}
.react-datepicker__day-names {
  padding-left: 10px;
  padding-right: 10px;
}
.react-datepicker__day-name {
  @apply text-gray-500;
}
.react-datepicker__day-name,
.react-datepicker__day {
  font-size: 14px;
  line-height: 32px;
  width: 32px;
  height: 32px;
}
.react-datepicker__day {
  border-radius: 50% !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  @apply bg-green;
  color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background: #2c7449;
}

.react-datepicker-popper {
  z-index: 100;
}
/* navigation */
.react-datepicker__navigation-icon::before {
  border-color: #858585;
  border-width: 2px 2px 0 0;
  height: 8px;
  top: 6px;
  width: 8px;
}
.react-datepicker__navigation {
  border-radius: 50%;
  top: 8px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.react-datepicker__navigation:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.react-datepicker__navigation-icon::before {
  top: 8px;
}
.react-datepicker__navigation-icon--previous::before {
  right: -3px;
}
.react-datepicker__navigation-icon--next::before {
  left: -3px;
}
.react-datepicker__navigation--next {
  right: 6px;
}
.react-datepicker__navigation--previous {
  left: auto;
  right: 44px;
}
