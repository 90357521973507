.multi-chart-loading {
  position: absolute;
  background-repeat: repeat;

  background-image:
      linear-gradient( rgba(230, 230, 230, 1) 1px, transparent 0 ),
      linear-gradient( 90deg, rgba(250, 250, 250, 0), rgba(250, 250, 250, 0.5) 15%, rgba(250, 250, 250, 0) 30% ),
      linear-gradient( rgba(242, 242, 242, 1) 24px, transparent 0 );

  background-size:
      1px 24px,
      30% 100%,
      2px 48px;

  background-position:
      0 0,
      0 0,
      0 0;

  animation: shineChart 0.5s infinite;
}

@keyframes shineChart {
  to {
    background-position:
        0 0,
        40% 0,
        0 0;
  }
}

