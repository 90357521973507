.button-primary {
  @apply bg-green border border-green text-white rounded cursor-pointer py-1 px-4 leading-5 whitespace-nowrap transition-all hover:bg-white hover:text-green focus:bg-white focus:text-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green disabled:opacity-30 disabled:bg-green disabled:text-white disabled:cursor-not-allowed;
}

.button-primary--sm {
  @apply px-9;
}

.button-primary__inverted {
  @apply bg-white border-gray-250 text-black rounded hover:bg-white hover:border-green focus:border-green;
}

.button-primary__inverted-red {
  @apply bg-gray-50 border-gray-250 text-red-bright rounded hover:bg-pink-light focus:bg-pink-light hover:text-red-bright focus:text-red-bright hover:border-red-bright focus:border-red-bright focus:ring-red-bright;
}

